<template>
    <div class="form-container">
        <div class="form-table">
            <div class="form-table__row">
                <div class="form-table__th">성명</div>
                <div class="form-table__td"><input type="text" id="writer-name" v-model="form.writer.name" @input="input" /></div>
            </div>
            <div class="form-table__row">
                <div class="form-table__th">연락처</div>
                <div class="form-table__td">
                    <input type="hidden" id="writer-phone" v-model="form.writer.phone" @input="input" />
                    <!-- 연락처 폼 -->
                    <div class="form-tel">
                        <select v-model="form.writer.phone1" class="select">
                            <option value="010">010</option>
                            <option value="011">011</option>
                            <option value="016">016</option>
                            <option value="017">017</option>
                            <option value="019">019</option>
                        </select>
                        <span class="text"></span>
                        <input v-model="form.writer.phone2" class="input" type="tel" maxlength="4">
                        <span class="text"></span>
                        <input v-model="form.writer.phone3" class="input" type="tel" maxlength="4">
                    </div>
                </div>
            </div>
            <div class="form-table__row">
                <div class="form-table__th">이메일</div>
                <div class="form-table__td">
                    <input type="hidden" id="writer-email" v-model="form.writer.email" @input="input" />
                    <!-- 이메일 폼 -->
                    <div class="form-email">
                        <input v-model="form.writer.email1" class="input" type="text">
                        <span class="text">@</span>
                        <input v-model="form.writer.email2" class="input" type="text">
                        <select v-model="form.writer.email3" class="select" @input="$event => form.writer.email2 = $event.target.value">
                            <option value="">직접입력</option>
                            <option value="naver.com">naver.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="google.com">google.com</option>
                        </select>
                    </div>
                </div>
            </div> 
            <div class="form-table__row">
                <div class="form-table__th">유형</div>
                <div class="form-table__td">
                    <div class="radio-wrap">
                        <label class="radio">
                            <input type="radio" class="input" v-model="form.meta.type" value="문의"><i class="icon"></i><span class="text">문의</span>
                        </label>
                        <label class="radio">
                            <input type="radio" class="input" v-model="form.meta.type" value="제휴"><i class="icon"></i><span class="text">제휴</span>
                        </label>
                    </div>
                </div>
            </div>            
            <div class="form-table__row">
                <div class="form-table__th">내용</div>
                <div class="form-table__td">
                    <!-- <naver-smarteditor v-model="form.content" @input="input"></naver-smarteditor> -->
                    <textarea name="" id="" cols="30" rows="10" v-model="form.content" @input="input"></textarea>
                </div>
            </div>
        </div>

        <div class="terms-box">
            <div class="terms-box__tit">개인정보 취급방침</div>
            <div class="terms-box__txt">
                <p>※ 총 칙</p>
                    <br/>
                    <p>1. 주식회사 앤에프티 코리아는 "정보통신망이용촉진및정보보호등에관한법률"상의 개인정보보호 규정과 정보통신부가 제정한 "개인정보보호지침" 및 "개인정보의 기술적/관리적 보호조치 기준"을 준수하고 있습니다. 또한 "개인정보보호정책"을 제정하여 회원들의 개인정보 보호를 위해 최선을 다하겠음을 선언합니다.</p>
                    <br/>
                    <p>2. 주식회사 앤에프티코리아의 "개인정보보호정책"은 관련 법률 및 정부 지침의 변경과 내부 방침 변경에 의해 변경될 수 있습니다. "개인정보보호방침"이 변경될 경우 변경사항은 홈페이지의 공지사항에 최소 7일간 게시됩니다.</p>
                    <br/>
                    <br/>
                    <p>※ 개인정보</p>
                    <br/>
                    <p>주식회사 앤에프티코리아는 귀하께서 이용약관의 내용에 대해 "동의한다" 버튼 또는 "동의하지 않는다" 버튼을 클릭할 수 있는 절차를 마련하여, "동의한다" 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 또한, 귀하께서 “동의한다” 버튼을 클릭하면 아래의 개인정보 수집 항목 중 “비밀번호”와 “주민등록번호”를 제외한 나머지 항목들은 주식회사 앤에프티코리아 서비스를 이행하기 위해 외주업체에 제공하는 것에 대해 동의한 것으로 간주합니다.</p>
                    <br/>
                    <p>1. "개인정보"의 범위는 정보통신망이용촉진및정보보호등에관한법률에서 규정하는 내용에 따라, "생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함한다)"를 의미합니다.</p>
                    <br/>
                    <p>2. 주식회사 앤에프티코리아의 이용자 확인, 대금결제, 이용 서비스의 소유자 확인, 개별회원에게 맞춤화된 서비스, 기타 부가서비스 등을 위하여 회원들의 개인정보를 수집ㆍ이용 합니다. 수집하는 개인정보 항목에 따른 구체적인 수집목적 및 이용 목적은 다음과 같습니다.</p>
                    <p>- 성명, 아이디, 비밀번호, 전화번호, 사용 SNS 정보: 회원제 서비스 이용에 따른 본인 확인 절차 및 정보제공에 이용,</p>
                    <p>- 이용 서비스의 소유자 확인</p>
                    <p>- 이메일주소, 전화번호, 팩스번호 : 도메인 관리 규정에 따른 필수 정보 확보, 고지사항 전 달, 불만처리 등을 위한 원활한 의사 소통</p>
                    <p>- 경로의 확보, 새로운 서비스 및 이벤트 정보 등의 안내</p>
                    <p>- 은행정보, 신용카드 정보 : 유료서비스 이용 및 구매에 대한 결제</p>
                    <p>- 주소 : 도메인 정보조회 제공, 청구서 배송지 확인</p>
                    <p>- 쿠키 ( 아이디 ) : 쿠키 운영을 통해 방문자들의 아이디를 자동 분석하여 등급별 차등화된 서비스 적용.</p>
                    <p>고객과 회원은 웹브라우저에서 옵션을 설정함으로써 쿠키가 저장될 때마다 확인을 거치거 나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 그러나 쿠키의 저장을 거부할 경 우 웹서비스 이용이 제한될 수 있습니다.</p>
                    <p>3. 주식회사 앤에프티코리아는 회원 개인정보를 위탁관리하지 않습니다.</p>
                    <br/>
                    <p>4. 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적 지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않습니 다.</p>
                    <br/>
                    <p>5. 개인정보의 보유 기간은 "회원이 주식회사 앤에프티코리아 홈페이지에 가입하는 순간부터 해지 신청 순간까지"입니다. 회원DB는 탈퇴 신청자의 개인정보가 탈퇴 즉시 삭제하지만 수집목적 및 제공받은 목적이 달성된 경우에도 법률의 규정에 의하여 보존할 필요성이 있는 경우에는 법률의 규정에 따라 고객의 개인정보를 보유할 수 있습니다.</p>
                    <p>- 계약 또는 청약철회 등에 관한 기록 : 5년</p>
                    <p>PC에 저장할 경우 암호화하여 저장하도록 시스템을 운영하고 있습니다.</p>
                    <br/>
                    <p>※ 의견수렴 및 불만처리</p>
                    <br/>
                    <p>주식회사 앤에프티코리아 회원 중 개인정보보호와 관련하여 불만이 있으신 분은 개인정보 관리책임자에게 의견을 주시면, 접수 즉시 조치하여 처리결과를 통보해 드리겠습니다. 개인정보 무단 유출이나 기타 심각한 개인정보 침해 시에는 정부에서 설치하여 운영중인 개인정보침해 신고센터(http://www.cyberpr ivacy.or.kr, http://www.1336.or.kr, 전화 02-1336,)에 불만처리 또는 중재를
                    신청하실 수도 있습니다.</p>
                    <br/>
                    <p>※ 개인정보관리 계획의 수립 및 시행</p>
                    <br/>
                    <p>주식회사 앤에프티코리아는 회사 규정에 별도의 전산관리규정을 마련하여 다음과 같은 사항을 준수하겠습니다.</p>
                    <br/>
                    <p>1. 개인정보관리책임자의 지정 등 개인정보보호 조칙의 구성, 운영에 관한 사항</p>
                    <p>2. 개인정보취급자의 교육에 관한 사항</p>
                    <p>3. 개인정보처리시스템의 접속 기록 유지 및 정기적인 확인 감독</p>
                    <p>4. 개인정보 출력 및 복사시의 보호조치</p>
                    <p>5. 기타 개인정보 보호를 위해 필요한 사항</p>
                    <br/>
                    <p>※ 개인정보 관리 담당자</p>
                    <br/>
                    <p>주식회사 앤에프티코리아의 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리담당자를 지정하고 있습니다.</p>
                    <br/>
                    <p>- 개인정보 관리 담당자</p>
                    <p>성 명 : 임종령</p>
                    <p>직 책 : 대표이사</p>
                    <p>전화번호 : 1661-9464</p>
                    <p>E-mail : xyz@semoo.co.kr</p>
                    <br/>
                    <p>※ 아동의 회원 가입에 대해</p>
                    <br/>
                    <p>1. 주식회사 앤에프티코리아는 아동의 개인정보를 보호하기 위하여 만 14세 미만의 아동이 회원 가입을 신청할 경우 법정대리인(부모)의 동의가 있어야 합니다. 부모님의 허락을 받지않은 14세 미만의 미성년자에 대해서는 주식회사 앤에프티코리아에서 임의로 회원에서 제외 할 수 있습니다.</p>
                    <br/>
                    <p>2. 만 14세 미만 미성년자의 법정대리인은 대리인의 책임하에 있는 미성년자의 개인정보에 대한 열람, 정정, 동의철회를 요청할 수 있으며, 이러한 요청이 있을 경우 주식회사 앤에프티코리아는 지체없이 필요한 조치를 취하겠습니다.</p>
                    <br/>
                    <p>※ 미성년자 거래시 철회에 대해</p>
                    <br/>
                    <p>주식회사 앤에프티코리아는 미성년자와의 거래시 사전에 법정대리인(부모)의 동의를 구할 의무가 있으며, 법정대리인(부모)의 동의를 얻지 못한 거래의 경우, 거래를 취소할 수 있습니다. 또한 거래 당사자인 미성년자의 법정대리인(부모)이 거래 성립 후 7일 이내에 철회를 요청할 경우, 거래를 철회하겠습니다.</p>
                    <br/>
                    <p>※ 광고성 정보 전송에 대해</p>
                    <br/>
                    <p>1. 주식회사 앤에프티코리아는 회원을 대상으로 주식회사 앤에프티코리아가 제공하고 있는 서비스에 대한 안내, 서비스에 대한 공지 등에 대한 메일을 자유롭게 보낼 수 있습니다.</p>
                    <br/>
                    <p>2. 주식회사 앤에프티코리아는 회원을 대상으로 광고성 정보를 전송할 수 있습니다. 단, 이러한 경우에는 (광고)라는 문구를 표시하여 회원들이 광고성 정보임을 쉽게 파악할 수 있게 하며, 수신거부 의사를 밝힌 회원에게는 광고성 정보를 전송하지 않겠습니다.​</p>
            </div>
        </div>

        <div class="form-buttons">
            <div class="center">
                <v-btn
                    @click="$emit('save')"
                    x-large
                    color="primary">
                    접수하기
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: this.$props.value,
        };
    },
    methods: {
        input(){
            this.$emit("input", this.form);
        }
    },
    watch: {
        value() {
            this.board = this.value;
        },
        "form.writer.email"(){ this.$emit("input", this.form); },
        "form.writer.email1"(){ this.$set(this.form.writer, "email", this.form.writer.email1 && this.form.writer.email2 ? `${this.form.writer.email1}@${this.form.writer.email2}`: null); },
        "form.writer.email2"(){ this.$set(this.form.writer, "email", this.form.writer.email1 && this.form.writer.email2 ? `${this.form.writer.email1}@${this.form.writer.email2}`: null); },

        "form.writer.phone"(){ this.$emit("input", this.form); },
        "form.writer.phone1"(){ this.$set(this.form.writer, "phone", this.form.writer.phone1 && this.form.writer.phone2 && this.form.writer.phone3 ? `${this.form.writer.phone1}-${this.form.writer.phone2}-${this.form.writer.phone3}`: null) },
        "form.writer.phone2"(){ this.$set(this.form.writer, "phone", this.form.writer.phone1 && this.form.writer.phone2 && this.form.writer.phone3 ? `${this.form.writer.phone1}-${this.form.writer.phone2}-${this.form.writer.phone3}`: null) },
        "form.writer.phone3"(){ this.$set(this.form.writer, "phone", this.form.writer.phone1 && this.form.writer.phone2 && this.form.writer.phone3 ? `${this.form.writer.phone1}-${this.form.writer.phone2}-${this.form.writer.phone3}`: null) },
    },
};
</script>
